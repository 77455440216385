.container-services{
    padding: 0;
}

.container-services .services{
    display: flex;
    flex-wrap: wrap;
}

.container-services h1{
    text-align: center;
}


@media screen and (min-width: 769px) {
    .container-services{
        padding: 3rem;
    }
}