.footer-container {
    margin: auto;
    background-color: var(--accent);
    height: 5rem;
    position: relative;
    text-align: center;
}

footer {
}
footer div.social-media-links {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    /*background: var(--);*/
    overflow: hidden;
    padding-bottom: 4px;
    text-align: center;
}
footer div.social-media-links ul {
    margin: 0;
    padding: 0;
}
footer div.social-media-links li {
    display: inline;
    margin: 0;
    padding: 0;
}
footer div.social-media-links a {
    border-bottom: 0px solid rgba(0, 0, 0, 0.95);
    border-radius: 4px;
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0), 0 6px 8px rgba(0, 0, 0, 0), 0 24px 24px rgba(0, 0, 0, 0), 0 36px 36px rgba(0, 0, 0, 0), 0 64px 64px rgba(0, 0, 0, 0), 0 64px 128px rgba(0, 0, 0, 0), 0 120px 0 rgba(0, 0, 0, 0), 0 86px 8px 6px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 30px;
    padding: 20px;
    position: relative;
    transition: 0.2s ease-in;
    width: 30px;
}
footer div.social-media-links a svg {
    left: 20px;
    position: absolute;
    top: 20px;
    height: 30px;
    width: 30px;
}
footer div.social-media-links a svg.glow path,
footer div.social-media-links a svg.glow circle {
    fill: rgba(0, 0, 0, 0);
}
footer div.social-media-links a svg path,
footer div.social-media-links a svg circle {
    fill: white;
    transition: 0.2s ease-in;
}
footer div.social-media-links a:hover {
    transform: translateY(-4px);
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.05),
    0 24px 24px rgba(0, 0, 0, 0.05), 0 36px 36px rgba(0, 0, 0, 0.05), 0 64px 64px
    rgba(0, 0, 0, 0.15), 0 64px 128px rgba(0, 0, 0, 0.15), 0 86px 8px 6px
    var(--primary-light), 0 83px 4px 0 var(--primary-b);
}


.footer-container .copy-right{
    position: absolute;
    bottom: 1rem;
    width: 100%;
    margin: auto;
    color: white;
    /*font-size: .6rem;*/
}

@media screen and (min-width: 769px) {
    .footer-container .copy-right{
        /*font-size: 2rem;*/
    }
}
