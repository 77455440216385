

* {
  --primary: #1c89de;
  --primary-b: #1c89de;
  --primary-light: rgb(128, 187, 232);
  --primary-light2: rgba(28, 137, 222, 0.25);
  --primary-opacity: rgba(0, 38, 242, 0.1);
  --primary-bg: #f7f9ff;

  --accent-dark: #2a2a2a;
  --accent: #333333;
  --accent-light: #777777;

  --grey: #a6a6a6;

  /*fix tap select copy mobile mode*/
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  scroll-behavior: smooth;
}

.right{
  text-align: right;
}
.primary{
  color: var(--primary);
}

@font-face {
  font-family: shamel;
  src: url(assets/fonts/shamel.ttf);
  font-display: block;
}

@font-face {
  font-family: shamel-2;
  src: url(assets/fonts/shamel2.ttf);
  font-display: block;
}
@font-face {
  font-family: GE-SS;
  src: url(assets/fonts/GE-SS-Three-Light.otf);
  font-display: block;
}

body {
  text-align: right;
  font-family: 'shamel-2', sans-serif;
  margin: 0;
  background-color: var(--primary-bg);
  color: var(--accent);
}
.w-100{
  width: 100%;
}
.center-text{
  text-align: center;
}
.under-line{
  height: 7px;
  width: 8%;
  margin: auto;
  background-color: var(--primary-b);
  border-radius: 20px;
}
