.about-us{
    position: relative;
    padding: 0 0;
    display: block;
    margin: 50rem 2rem 0 2rem;
}
.about-us .image{
    width: 100%;
}

.about-us .details{
    width: 100%;
    display: block;
    position: relative;
    margin-top: 2rem;
}

.about-us .details h1{
    margin: 0;
}
.about-us .details .under-line{
    margin: 0;
    width: 12%;
}



@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    .about-us{
        padding: 7rem 5rem 10rem 0;
        display: flex;
        margin-top: 30rem;
    }
    .about-us .image{
        width: 45%;
    }
    .about-us .details{
        width: 55%;
    }

    /*.about-us .details h1{*/
    /*    font-size: 3rem;*/
    /*    margin: 0;*/
    /*}*/
    /*.about-us .details .under-line{*/
    /*    margin: 0;*/
    /*    width: 12%;*/
    /*}*/
    /*.about-us .details p{*/
    /*    font-size: 1.2rem;*/
    /*}*/
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}

/*@media screen and (min-width: 1025px) {*/

/*}*/
/*@media screen and (min-width: 769px) {*/
/*    .about-us{*/
/*        padding: 20rem 5rem;*/
/*        margin-top: 200%;*/
/*    }*/
/*    .about-us .image{*/
/*        width: 50%;*/
/*    }*/
/*    .about-us .details{*/
/*        width: 50%;*/
/*        display: block;*/
/*        position: relative;*/
/*        margin-top: 0;*/
/*    }*/

/*}*/
/*@media screen and (min-width: 481px) {*/

/*}*/
/*@media screen and (min-width: 320px) {*/

/*}*/
