
a {
    color: var(--accent);
}

/* navbar */

.navbar {
    height: 3.6rem;
    position: fixed;
    width: 100%;
    z-index: 3;
    transition: 0.3s;
    box-shadow: 0 0 0 0 rgba(0,0,0,.1);
}

.navbar ul {
    margin: 0;
    padding: 10px 0 0 40px;
    list-style: none;
    overflow: hidden;
    background-color: var(--primary-bg);
}

.navbar li a {
    display: block;
    padding: 13px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
}

.navbar li a:hover,
.navbar .menu-btn:hover {
    background-color: var(--primary-opacity);
}
.navbar .header-icons {
    float: left;
    padding: 1rem 0 0 1rem;
    font-size: 1.5em;
    letter-spacing: 20px;
}
.navbar .header-icons .icon{
    color: var(--accent);
}
.navbar .header-icons .icon:hover{
    color: var(--primary);
}
.navbar .header-icons .download-icon{
    color: var(--primary);
}
.navbar .header-icons .download-icon:hover{
    color: green;
}

.download-pdf{
    background-color: var(--primary);
    color: white;
    display: none;
}
.download-pdf:hover{
    background-color: var(--primary);
    color: var(--primary);
}

/* menu */

.navbar .menu {
    clear: both;
    max-height: 0;
    font-family: shamel,sans-serif;
    transition: max-height .2s ease-out;
    box-shadow: 0 5px 4px 0 rgba(0,0,0,.1);
}

/* menu icon */

.navbar .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 15px 0 0;
    position: relative;
    user-select: none;
}

.navbar .menu-icon .navicon {
    background: #333;
    display: block;
    height: 3px;
    position: relative;
    transition: background .2s ease-out;
    margin-bottom: 2px;
    width: 30px;
}

.navbar .menu-icon .navicon:before,
.navbar .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.navbar .menu-icon .navicon:before {
    top: 8px;
}

.navbar .menu-icon .navicon:after {
    top: -8px;
}

/* menu btn */

.navbar .menu-btn {
    display: none;
}

.navbar .menu-btn:checked ~ .menu {
    max-height: 350px;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.navbar .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    .navbar ul {
        transform: rotate(180deg);
    }
    .navbar ul > li {
        transform: rotate(-180deg);
    }
    .navbar .header-icons {
        width: 160px;
    }
    .navbar li {
        float: left;
    }
    .navbar li a {
        padding: 13.4px 30px;
    }
    .navbar .menu {
        clear: none;
        float: right;
        max-height: none;
    }
    .navbar .menu-icon {
        display: none;
    }
    .download-pdf{
        display: block;
    }
    .navbar .header-icons .download-icon{
        display: none;
    }

    .navbar .menu {
        font-family: shamel-2,sans-serif;
    }
}
@media screen and (min-width: 481px) {
    .navbar .header-icons {
        margin: 0 0 -16px 0;
    }
    .navbar .menu {
        padding-top: 0;
    }
}
@media screen and (min-width: 320px) {
    .navbar ul {
        padding: 0;
    }
}
