.blob-container{
    position: relative;
}
.blob-container .blob-image{
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 60% 40% 43% 57% / 66% 30% 70% 34%;
}

.blob-container .blob-image-bg{
    background-color: var(--primary);
    position: absolute;
    width: 100%;
    height: 350px;
    border-radius: 41% 59% 28% 72% / 60% 41% 59% 40%;
}
.blob-container .blob-image-border-dashed{
    border: dashed var(--accent) 2px;
    position: absolute;
    width: 100%;
    height: 350px;
    border-radius: 41% 59% 28% 72% / 60% 41% 59% 40%;
}


@media screen and (min-width: 1025px) {
    .blob-container .blob-image,
    .blob-container .blob-image-bg,
    .blob-container .blob-image-border-dashed
    {
        width: 420px;
        height: 420px;
    }
}
@media screen and (min-width: 769px) {
    .blob-container .blob-image,
    .blob-container .blob-image-bg,
    .blob-container .blob-image-border-dashed
    {
        /*width: 320px;*/
        /*height: 320px;*/
    }
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}
