.ag-courses_item {
    flex-basis: calc(33% - 30px);
    background: white;
    color: var(--primary);
    margin: 0 15px 30px;
    overflow: hidden;
    border-radius: 18px;
    cursor: pointer;
    text-align: center;
    z-index: 2;
    position: relative;
    border: dashed 1px var(--primary);
}

.ag-courses_item:hover {
    color: white;
    border: solid 1px var(--primary);
}
.ag-courses-item_link {
    display: block;
    padding: 30px 20px;
    overflow: hidden;
    position: relative;
}

.ag-courses_item .icon{
    z-index: 2;
    position: relative;
    font-size: 40px;
}

.ag-courses_item:hover .ag-courses-item_bg {
    -ms-transform: scale(10);
    transform: scale(10);
}
.ag-courses-item_title {
    min-height: 87px;
    margin: 0 0 25px;
    overflow: hidden;
    font-weight: bold;
    font-size: 20px;
    z-index: 2;
    position: relative;
}


.ag-courses-item_bg {
    height: 128px;
    width: 128px;
    background-color: var(--primary);
    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;
    border-radius: 50%;
    -webkit-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .5s ease;
}

@media only screen and (max-width: 979px) {
    .ag-courses_item {
        flex-basis: calc(49% - 30px);
    }
    .ag-courses-item_title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .ag-format-container {
        width: 96%;
    }

}
@media only screen and (max-width: 639px) {
    .ag-courses_item {
        flex-basis: 100%;
    }
    .ag-courses-item_title {
        margin-top: 20px;
        min-height: 32px;
        line-height: 1;
        font-size: 20px;
    }
    .ag-courses-item_link {
        padding: 22px 40px;
    }
    .ag-courses-item_date-box {
        font-size: 16px;
    }
}
