.header {
    width: 100%;
    position: absolute;
    top:25vh;
}

.header h1{
    margin-right: 2rem;
    color: var(--primary);
    font-family: GE-SS, serif;
    font-size: 70px;
}
.header h3{
    margin-right: 3rem;
    margin-top: -54px;
    color: var(--accent-light);
    font-size: 18px;
}

.header img{
    margin-right: 2rem;
    width: 16rem;
}

.header .btn-order{
    margin-top: 1rem;
    margin-right: 2rem;
    border-radius: 5px;
    padding: .5rem 3rem;
    border: 0;
    background-color: var(--accent);
    color: white;
}
.header .btn-order:hover{
    background-color: var(--primary);
    color: white;
    position: absolute;
}

.area{
    background: -webkit-linear-gradient(to left, var(--primary-light), var(--primary));
    width: 100%;
    height:10vh;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: var(--primary-opacity);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    .header h1{
        margin-right: 10rem;
        color: var(--primary);
        font-family: shamel, serif;
        font-size: 80px;
    }

    .header h3{
        margin-right: 12rem;
        margin-top: -5rem;
        color: var(--accent-light);
        font-size: 25px;
    }

    .header img{
        margin-right: 10rem;
        width: 26rem;
    }

    .circles{
        height: 70%;
    }
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}
