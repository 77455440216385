.app{
    width: 100%;
}
.container{
    /*max-width: 1200px;*/
}

h1{
    font-size: 2rem;
}
h2{
    font-size: 1.4rem;
}
p, li{
    font-size: .8rem;
    line-height: 2;
}
@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    h1{
        font-size: 3rem;
    }
    h2{
        font-size: 2rem;
    }
    p, li{
        font-size: 1rem;
    }
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}
