.typing {
    /*margin: 150px auto;*/
}

.typing h1 {
    font-family: shamel-2, serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    color: var(--primary);
}

.typing .word {
    /*display: block;*/
}

.typing .word span {
    color: var(--accent);
    position: relative;
}

.typing .word span::before {
    content: "";
    width: 8px;
    height: 100%;
    background: #333333;
    display: block;
    position: absolute;
    left: -10px;
    top: 0;
    animation: blink 0.5s ease infinite alternate-reverse;
}

@keyframes blink {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0;
    }
}

.typing .word.empty {
    visibility: hidden;
}

.typing .word.empty span::after {
    visibility: visible;
    right: 0;
}




@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    .typing h1 {
        font-size: 30px;
    }
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}
