.our-values {
    min-height: 50vh;
    background: var(--accent);
    font-size: 1.1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.our-values section > .box > .box > * {
    margin-block: 0.4em;
    text-transform: capitalize;
    border-radius: 20px;
}
.our-values section {
    display: grid;
    grid-template-columns: repeat(1,100%);
    grid-auto-rows: 1fr;
    gap: 20px;
    padding: 20px 0;
    margin-inline: max(1rem,50% - 450px);
    color: #fff;
    position: relative;
    z-index: 0;
}
.our-values section .icon{
    width: 35px;
    position: absolute;
    padding: 1em 2em 0 0;
    color: white;
    font-family: Arial,sans-serif;
}
.our-values section .box .box p{
    color: var(--grey);
}
.our-values section > .box {
    display: grid;
    clip-path: inset(0);
    cursor: pointer;
    border: 1px solid rgba(101, 101, 101, 0.23);
}
.our-values section > .box > .box {
    padding: 3em 2em 2em 2em;
    display: grid;
    place-content: center;
    border: 2px solid #0000;
    --g: linear-gradient(#000 0 0);
    -webkit-mask: var(--g) text,var(--g) padding-box,var(--g);
    -webkit-mask-composite: xor;
}
.our-values section > .box::before,
.our-values section > .box > .box::before{
    content:"";
    position: absolute;
    z-index: -1;
    inset: 0;
    background: radial-gradient(250px at var(--x) var(--y),#aaa5,#0000);
    opacity: 0;
    pointer-events: none;
    transition: .3s;
}
.our-values section > .box > .box::before {
    border-radius: 20px;
    background: radial-gradient(200px at var(--x) var(--y),
    #aaa9,
    rgba(60, 158, 255, 0)
    );
    opacity: 1;
}
.our-values section > .box:hover::before {
    opacity: 1;
}
.our-values section:not(:hover) *:before {
    opacity: 0;
}



@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    .our-values section {
        grid-template-columns: repeat(2,500px);
    }

    .our-values section {
        padding: 20px;
    }
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}

