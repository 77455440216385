.moral-section {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
}

.moral-section h1 {
    color: var(--accent);
    text-align: center;
    position: relative;
}
.moral-section h3 {
    color: #262626;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 4px;
    padding-top: 8px;
}

.moral-section p {
    /*font-size: 17px;*/
    font-weight: 400;
    color: #666666;
}
.moral-section p {
    /*font-size: 14px;*/
}

.moral-section .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #00838d;
    border-radius: 0 4px 0 32px;
}

.moral-section .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
}


.moral-section .card4 {
    display: block;
    top: 0;
    position: relative;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 1rem;
    text-decoration: none;
    overflow: hidden;
    border: 2px solid #cccccc;
    cursor: pointer;
}

.moral-section .card4 .title{
    display: flex;
}
.moral-section .card4 img{
    width: 40px;
    margin-left: 20px;
}
.moral-section .card4 .go-corner {
    background-color: #00838d;
    height: 100%;
    width: 16px;
    padding-right: 9px;
    border-radius: 0;
    transform: skew(6deg);
    margin-right: -36px;
    align-items: start;
    background-image: linear-gradient(-45deg, var(--primary) 1%, var(--primary-b) 100%);
    transition: all 0.3s ease-out;
}
.moral-section .card4 .go-arrow {
    transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
}
.moral-section .card4:hover {
    border: 2px solid var(--primary-b);
}
.moral-section .card4 h3 {
    margin-top: 8px;
}

.moral-section .card4:hover .go-corner {
    margin-right: -12px;
}
.moral-section .card4:hover .go-arrow {
    opacity: 1;
}



@media screen and (min-width: 769px) {
    .moral-section .card4 {
        margin: 2rem;
    }
}
