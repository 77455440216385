/*.why-us {*/
/*    margin: 3rem 0 3rem 1rem;*/
/*}*/
/*.why-us h2{*/
/*    text-align: center;*/
/*    margin: .1rem 0;*/
/*}*/
/*.why-us ul{*/
/*    margin: 2rem 0;*/
/*    color: var(--primary);*/
/*}*/

/*.why-us li{*/
/*    margin: 0;*/
/*}*/


/*@media screen and (min-width: 769px) {*/
/*    .why-us {*/
/*        margin: 3rem;*/
/*    }*/
/*}*/



.why-us {
    background: #fff;
    margin: 3rem 1rem;
    padding: 1rem .1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}
.why-us .more-sales{
    line-height: 2.3;
    font-size: 1rem;
    padding: 0 25px;
}
.why-us h2 {
    padding: 0 15px;
}

.why-us .list {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}
.why-us .list li {
    line-height: 1rem;
    padding: 10px 10px;
    display: flex;
    margin: 0 10px;
    border-radius: 4px;
    font-weight: 300;
    align-items: center;
    cursor: pointer;
    color: #656565;
    transition: all 0.3s ease;
}
.why-us .list li:hover {
    background: var(--primary-light2);
}
.why-us .list li:hover .circle {
    color: var(--accent);
}

.why-us .list li .circle {
    align-self: center;
    color: var(--primary);
    padding-top: 15px;
}
.why-us .list li .circle::before {
    margin-left: 10px;
    content: "•";
    font-size: 50pt;
    vertical-align: middle;
}


@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 769px) {
    .why-us {
        margin: 3rem 3rem;
        padding: 3rem 2rem;
    }

    .why-us .list li .circle::before {
        font-size: 43pt;
        margin-left: 20px;
    }

    .why-us .list li {
        line-height: 1;
        margin-bottom: 0;
    }
    .why-us .more-sales{
        line-height: 2.3;
        font-size: 1.2rem;
        padding: 0 15px;
    }
}
@media screen and (min-width: 481px) {

}
@media screen and (min-width: 320px) {

}

